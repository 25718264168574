import axios, { config } from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";

import type { CollectionResult, Result } from "../types/result";
import type { PaymentInterface } from "../types/creator";
import { paramsParser } from "../core";
import type { Currency, GenericListParams, Relationship } from "../types";
import type {
  PayoutEntity,
  PayoutState,
  PayoutApproval,
  FeePredictionEntity,
} from "../types/payout";

export type PayoutOrderOptions = "createdAt" | "amount";

export type PayoutListParams = GenericListParams & {
  interfaces?: PaymentInterface[];
  states?: PayoutState[];
  organizationId?: string;
  externalId?: string;
  externalTargetId?: string;
  createdAtSince?: string;
  createdAtBefore?: string;
  order?: Partial<Record<PayoutOrderOptions, "asc" | "desc">>;
};

export type RequestPayoutBody = {
  interface: PaymentInterface;
  organizationId: string;
  money: {
    amount: number;
    currency: Currency;
  };
  requestComment?: string;
};

export type UpdatePayoutBody = {
  approval?: PayoutApproval;
  interface?: PaymentInterface;
  externalTargetId?: string;
  externalId?: string;
  createdAt?: string;
  payoutFeesAmount?: number;
  money?: {
    amount: number;
    currency: Currency;
  };
  requestComment?: string;
};

export type ExpectedStrpeFeesBody = {
  money: {
    amount: number;
    currency: Currency;
  };
};

export type ExpectedPaypalFeesBody = {
  receiverCountryCode: string;
  money: {
    amount: number;
    currency: Currency;
  };
};

export class Payout {
  static async getPayouts(params: PayoutListParams, auth: string) {
    const resp = await axios.get<CollectionResult<PayoutEntity>>(
      `${config.paymentsUrl}/payout${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data);
  }

  static async getSinglePayout(
    id: string,
    includes: Relationship["type"][],
    auth: string,
  ) {
    const resp = await axios.get<Result<PayoutEntity>>(
      `${config.paymentsUrl}/payout/${id}${paramsParser({ includes })}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async requestPayout(body: RequestPayoutBody, auth: string) {
    const resp = await axios.post<Result<PayoutEntity>>(
      `${config.paymentsUrl}/payout/request`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async updatePayout(id: string, body: UpdatePayoutBody, auth: string) {
    const resp = await axios.post<Result<PayoutEntity>>(
      `${config.paymentsUrl}/payout/${id}`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getStripeExpectedFees(
    body: ExpectedStrpeFeesBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<FeePredictionEntity>>(
      `${config.paymentsUrl}/creator/stripe/fees`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async getPaypalExpectedFees(
    body: ExpectedPaypalFeesBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<FeePredictionEntity>>(
      `${config.paymentsUrl}/creator/paypal/fees`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }
}
